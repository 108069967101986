* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.wrapper {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 0;
  min-height: 90vh;
}
.main {
  box-sizing: border-box;
  background-color: #f0eeeb;
  min-height: 100vh;
}
.form {
  display: grid;
  max-width: 100%;
  position: relative;
}
/*select*/
/*инпут*/
.react-select__control {
  height: 50px;
  border: 1px solid #5c82e2 !important;
}
.react-select__menu-list {
  -webkit-color: rgba(72, 72, 72, 1) !important;
  color: rgba(72, 72, 72, 1) !important;
  font-size: 14px;
}
.react-select__input-container {
  -webkit-color: rgba(72, 72, 72, 1) !important;
  color: rgba(72, 72, 72, 1) !important;
}
/*индикатор-разделитель*/
.react-select__indicator-separator {
  background-color: #5c82e2 !important;
  /*display: none;*/
}
/*галочка*/
.react-select__indicator {
  color: #5c82e2 !important;
}
.button {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  text-align: -moz-center;
  text-align: -webkit-center;
  border-radius: 4px;
  border: none;
  background-color: #5c82e2;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.button:active {
  opacity: 0.7;
}
.block {
  padding: 5px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
}
.block__title {
  -webkit-color: rgba(72, 72, 72, 1);
  color: rgba(72, 72, 72, 1);
  font-weight: 600;
}
.block__span {
  margin: 0;
  text-align: center;
  -webkit-color: rgba(72, 72, 72, 1);
  color: rgba(72, 72, 72, 1);
  font-size: 14px;
  font-weight: 400;
}
.block_article {
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.block__span_type-article {
  padding-left: 10px;
}
.block__span_type-name {
  text-align: left;
  min-height: fit-content;
  white-space: normal;
}

.update {
  text-align: center;
  margin-top: 20px;
}
